/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  CheckboxField,
  Flex,
  Grid,
  Radio,
  RadioGroupField,
  SelectField,
  TextField,
} from "@aws-amplify/ui-react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { UserBasicInformation } from "../models";
import { fetchByPath, validateField } from "./utils";
import { DataStore } from "aws-amplify";
export default function Registration(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    City: "",
    Country: "",
    DOB: "",
    Specialization: "",
    SubSpecialization: "",
    WhatsappNumber: "",
    PhoneNumber: "",
    UserType: undefined,
    Linekdin: "",
    YearsOfExperience: "",
    Field0: false,
  };
  const [City, setCity] = React.useState(initialValues.City);
  const [Country, setCountry] = React.useState(initialValues.Country);
  const [DOB, setDOB] = React.useState(initialValues.DOB);
  const [Specialization, setSpecialization] = React.useState(
    initialValues.Specialization
  );
  const [SubSpecialization, setSubSpecialization] = React.useState(
    initialValues.SubSpecialization
  );
  const [WhatsappNumber, setWhatsappNumber] = React.useState(
    initialValues.WhatsappNumber
  );
  const [PhoneNumber, setPhoneNumber] = React.useState(
    initialValues.PhoneNumber
  );
  const [UserType, setUserType] = React.useState(initialValues.UserType);
  const [Linekdin, setLinekdin] = React.useState(initialValues.Linekdin);
  const [YearsOfExperience, setYearsOfExperience] = React.useState(
    initialValues.YearsOfExperience
  );
  const [Field0, setField0] = React.useState(initialValues.Field0);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setCity(initialValues.City);
    setCountry(initialValues.Country);
    setDOB(initialValues.DOB);
    setSpecialization(initialValues.Specialization);
    setSubSpecialization(initialValues.SubSpecialization);
    setWhatsappNumber(initialValues.WhatsappNumber);
    setPhoneNumber(initialValues.PhoneNumber);
    setUserType(initialValues.UserType);
    setLinekdin(initialValues.Linekdin);
    setYearsOfExperience(initialValues.YearsOfExperience);
    setField0(initialValues.Field0);
    setErrors({});
  };
  const validations = {
    City: [{ type: "Required" }],
    Country: [{ type: "Required" }],
    DOB: [{ type: "Required" }],
    Specialization: [{ type: "Required" }],
    SubSpecialization: [{ type: "Required" }],
    WhatsappNumber: [{ type: "Required" }, { type: "Phone" }],
    PhoneNumber: [{ type: "Required" }],
    UserType: [{ type: "Required" }],
    Linekdin: [{ type: "URL" }],
    YearsOfExperience: [
      {
        type: "GreaterThanNum",
        numValues: [0],
        validationMessage: "The value must be greater than 0",
      },
      {
        type: "LessThanNum",
        numValues: [50],
        validationMessage: "The value must be less than 50",
      },
    ],
    Field0: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          City,
          Country,
          DOB,
          Specialization,
          SubSpecialization,
          WhatsappNumber,
          PhoneNumber,
          UserType,
          Linekdin,
          YearsOfExperience,
          Field0,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value.trim() === "") {
              modelFields[key] = undefined;
            }
          });
          const modelFieldsToSave = {
            City: modelFields.City,
            Country: modelFields.Country,
            DOB: modelFields.DOB,
            Specialization: modelFields.Specialization,
            SubSpecialization: modelFields.SubSpecialization,
            WhatsappNumber: modelFields.WhatsappNumber,
            PhoneNumber: modelFields.PhoneNumber,
            UserType: modelFields.UserType,
            Linekdin: modelFields.Linekdin,
            YearsOfExperience: modelFields.YearsOfExperience,
          };
          await DataStore.save(new UserBasicInformation(modelFieldsToSave));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "Registration")}
      {...rest}
    >
      <TextField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>مدينة الاقامة</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        isRequired={true}
        isReadOnly={false}
        value={City}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              City: value,
              Country,
              DOB,
              Specialization,
              SubSpecialization,
              WhatsappNumber,
              PhoneNumber,
              UserType,
              Linekdin,
              YearsOfExperience,
              Field0,
            };
            const result = onChange(modelFields);
            value = result?.City ?? value;
          }
          if (errors.City?.hasError) {
            runValidationTasks("City", value);
          }
          setCity(value);
        }}
        onBlur={() => runValidationTasks("City", City)}
        errorMessage={errors.City?.errorMessage}
        hasError={errors.City?.hasError}
        {...getOverrideProps(overrides, "City")}
      ></TextField>
      <TextField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>بلد الاقامة</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        isRequired={true}
        isReadOnly={false}
        value={Country}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              City,
              Country: value,
              DOB,
              Specialization,
              SubSpecialization,
              WhatsappNumber,
              PhoneNumber,
              UserType,
              Linekdin,
              YearsOfExperience,
              Field0,
            };
            const result = onChange(modelFields);
            value = result?.Country ?? value;
          }
          if (errors.Country?.hasError) {
            runValidationTasks("Country", value);
          }
          setCountry(value);
        }}
        onBlur={() => runValidationTasks("Country", Country)}
        errorMessage={errors.Country?.errorMessage}
        hasError={errors.Country?.hasError}
        {...getOverrideProps(overrides, "Country")}
      ></TextField>
      <TextField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>تاريخ الميلاد</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        isRequired={true}
        isReadOnly={false}
        type="date"
        value={DOB}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              City,
              Country,
              DOB: value,
              Specialization,
              SubSpecialization,
              WhatsappNumber,
              PhoneNumber,
              UserType,
              Linekdin,
              YearsOfExperience,
              Field0,
            };
            const result = onChange(modelFields);
            value = result?.DOB ?? value;
          }
          if (errors.DOB?.hasError) {
            runValidationTasks("DOB", value);
          }
          setDOB(value);
        }}
        onBlur={() => runValidationTasks("DOB", DOB)}
        errorMessage={errors.DOB?.errorMessage}
        hasError={errors.DOB?.hasError}
        {...getOverrideProps(overrides, "DOB")}
      ></TextField>
      <SelectField
        label="التخصص"
        placeholder="..."
        isDisabled={false}
        value={Specialization}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              City,
              Country,
              DOB,
              Specialization: value,
              SubSpecialization,
              WhatsappNumber,
              PhoneNumber,
              UserType,
              Linekdin,
              YearsOfExperience,
              Field0,
            };
            const result = onChange(modelFields);
            value = result?.Specialization ?? value;
          }
          if (errors.Specialization?.hasError) {
            runValidationTasks("Specialization", value);
          }
          setSpecialization(value);
        }}
        onBlur={() => runValidationTasks("Specialization", Specialization)}
        errorMessage={errors.Specialization?.errorMessage}
        hasError={errors.Specialization?.hasError}
        {...getOverrideProps(overrides, "Specialization")}
      >
        <option
          children="الهندسة"
          value="الهندسة"
          {...getOverrideProps(overrides, "Specializationoption0")}
        ></option>
        <option
          children="الأعمال"
          value="الأعمال"
          {...getOverrideProps(overrides, "Specializationoption1")}
        ></option>
        <option
          children="الفنون والعلوم الإنسانية"
          value="الفنون والعلوم الإنسانية"
          {...getOverrideProps(overrides, "Specializationoption2")}
        ></option>
        <option
          children="العلوم"
          value="العلوم"
          {...getOverrideProps(overrides, "Specializationoption3")}
        ></option>
        <option
          children="العلوم الاجتماعية"
          value="العلوم الاجتماعية"
          {...getOverrideProps(overrides, "Specializationoption4")}
        ></option>
        <option
          children="الطب"
          value="الطب"
          {...getOverrideProps(overrides, "Specializationoption5")}
        ></option>
      </SelectField>
      <TextField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>التخصص الدقيق</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        isRequired={true}
        isReadOnly={false}
        placeholder="الهندسة المدنية"
        value={SubSpecialization}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              City,
              Country,
              DOB,
              Specialization,
              SubSpecialization: value,
              WhatsappNumber,
              PhoneNumber,
              UserType,
              Linekdin,
              YearsOfExperience,
              Field0,
            };
            const result = onChange(modelFields);
            value = result?.SubSpecialization ?? value;
          }
          if (errors.SubSpecialization?.hasError) {
            runValidationTasks("SubSpecialization", value);
          }
          setSubSpecialization(value);
        }}
        onBlur={() =>
          runValidationTasks("SubSpecialization", SubSpecialization)
        }
        errorMessage={errors.SubSpecialization?.errorMessage}
        hasError={errors.SubSpecialization?.hasError}
        {...getOverrideProps(overrides, "SubSpecialization")}
      ></TextField>
      <TextField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>رقم الواتساب</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        isRequired={true}
        isReadOnly={false}
        type="tel"
        value={WhatsappNumber}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              City,
              Country,
              DOB,
              Specialization,
              SubSpecialization,
              WhatsappNumber: value,
              PhoneNumber,
              UserType,
              Linekdin,
              YearsOfExperience,
              Field0,
            };
            const result = onChange(modelFields);
            value = result?.WhatsappNumber ?? value;
          }
          if (errors.WhatsappNumber?.hasError) {
            runValidationTasks("WhatsappNumber", value);
          }
          setWhatsappNumber(value);
        }}
        onBlur={() => runValidationTasks("WhatsappNumber", WhatsappNumber)}
        errorMessage={errors.WhatsappNumber?.errorMessage}
        hasError={errors.WhatsappNumber?.hasError}
        {...getOverrideProps(overrides, "WhatsappNumber")}
      ></TextField>
      <TextField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>رقم الهاتف</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        isRequired={true}
        isReadOnly={false}
        value={PhoneNumber}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              City,
              Country,
              DOB,
              Specialization,
              SubSpecialization,
              WhatsappNumber,
              PhoneNumber: value,
              UserType,
              Linekdin,
              YearsOfExperience,
              Field0,
            };
            const result = onChange(modelFields);
            value = result?.PhoneNumber ?? value;
          }
          if (errors.PhoneNumber?.hasError) {
            runValidationTasks("PhoneNumber", value);
          }
          setPhoneNumber(value);
        }}
        onBlur={() => runValidationTasks("PhoneNumber", PhoneNumber)}
        errorMessage={errors.PhoneNumber?.errorMessage}
        hasError={errors.PhoneNumber?.hasError}
        {...getOverrideProps(overrides, "PhoneNumber")}
      ></TextField>
      <RadioGroupField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>أرغب في</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        name="UserType"
        isReadOnly={false}
        isRequired={true}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              City,
              Country,
              DOB,
              Specialization,
              SubSpecialization,
              WhatsappNumber,
              PhoneNumber,
              UserType: value,
              Linekdin,
              YearsOfExperience,
              Field0,
            };
            const result = onChange(modelFields);
            value = result?.UserType ?? value;
          }
          if (errors.UserType?.hasError) {
            runValidationTasks("UserType", value);
          }
          setUserType(value);
        }}
        onBlur={() => runValidationTasks("UserType", UserType)}
        errorMessage={errors.UserType?.errorMessage}
        hasError={errors.UserType?.hasError}
        {...getOverrideProps(overrides, "UserType")}
      >
        <Radio
          children="البحث عن عمل"
          value="SEEKER"
          {...getOverrideProps(overrides, "UserTypeRadio0")}
        ></Radio>
        <Radio
          children="تقديم المساعدة للباحثين عن عمل"
          value="COACH"
          {...getOverrideProps(overrides, "UserTypeRadio1")}
        ></Radio>
      </RadioGroupField>
      <TextField
        label="Linekdin بروفايل "
        isRequired={false}
        isReadOnly={false}
        value={Linekdin}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              City,
              Country,
              DOB,
              Specialization,
              SubSpecialization,
              WhatsappNumber,
              PhoneNumber,
              UserType,
              Linekdin: value,
              YearsOfExperience,
              Field0,
            };
            const result = onChange(modelFields);
            value = result?.Linekdin ?? value;
          }
          if (errors.Linekdin?.hasError) {
            runValidationTasks("Linekdin", value);
          }
          setLinekdin(value);
        }}
        onBlur={() => runValidationTasks("Linekdin", Linekdin)}
        errorMessage={errors.Linekdin?.errorMessage}
        hasError={errors.Linekdin?.hasError}
        {...getOverrideProps(overrides, "Linekdin")}
      ></TextField>
      <TextField
        label="عدد سنوات الخبرة"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={YearsOfExperience}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              City,
              Country,
              DOB,
              Specialization,
              SubSpecialization,
              WhatsappNumber,
              PhoneNumber,
              UserType,
              Linekdin,
              YearsOfExperience: value,
              Field0,
            };
            const result = onChange(modelFields);
            value = result?.YearsOfExperience ?? value;
          }
          if (errors.YearsOfExperience?.hasError) {
            runValidationTasks("YearsOfExperience", value);
          }
          setYearsOfExperience(value);
        }}
        onBlur={() =>
          runValidationTasks("YearsOfExperience", YearsOfExperience)
        }
        errorMessage={errors.YearsOfExperience?.errorMessage}
        hasError={errors.YearsOfExperience?.hasError}
        {...getOverrideProps(overrides, "YearsOfExperience")}
      ></TextField>
      <CheckboxField
        label="أوافق علي حفظ بياناتي في قاعدة بيانات قادرين"
        name="fieldName"
        value="fieldName"
        checked={Field0}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              City,
              Country,
              DOB,
              Specialization,
              SubSpecialization,
              WhatsappNumber,
              PhoneNumber,
              UserType,
              Linekdin,
              YearsOfExperience,
              Field0: value,
            };
            const result = onChange(modelFields);
            value = result?.Field0 ?? value;
          }
          if (errors.Field0?.hasError) {
            runValidationTasks("Field0", value);
          }
          setField0(value);
        }}
        onBlur={() => runValidationTasks("Field0", Field0)}
        errorMessage={errors.Field0?.errorMessage}
        hasError={errors.Field0?.hasError}
        {...getOverrideProps(overrides, "Field0")}
      ></CheckboxField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="مسح"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="تقديم"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
