import logo from './top_logo.png';
import './App.css';
import { Amplify} from 'aws-amplify';
import {Authenticator, Image, Text} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import {
    Registration
} from './ui-components';


Amplify.configure(awsExports);

function Header() {
    return (
        <>
            <Image src={logo} alt="Logo" style={{ maxWidth: "100%", height: "auto" }} />
        </>
    );
}


function TextIntro() {
    return (
        <>
            <Text fontFamily="arial" fontSize="25px" width="50%" textAlign="center" marginBottom="10rem" marginTop="10rem">
                لا تدع الحرب تحطم أحلامك. انت تستحق الحياة التي تريدها. ونحن هنا لمساعدتك على تحقيقها.

                نحن قادرين، مبادرة تطوعية تهتم بك وبمستقبلك. نقدم لك الدعم النفسي واللوجستي الذي تحتاجه للتغلب على التحديات التي تواجهك بالخارج.

                كما نقدم لك برنامج تدريبي مصمم خصيصًا لتطوير مهاراتك وزيادة فرصك في الحصول على عمل في بلد أجنبي.انضم إلى قادرين الآن واستعد للتغيير.

            </Text>
        </>
    );
}


function PostAuthentication(signOut, user) {
    console.log('user=${user}')
    return (
        <Registration />
        )
}


function Authentication() {
    return (
        <>
            <Authenticator>
                {({ signOut, user }) => (
                    <main>
                        <h1>Hello {user.username}</h1>
                        <PostAuthentication signOut={signOut} user={user} />

                        <button onClick={signOut}>Sign out</button>
                    </main>
                )}
            </Authenticator>
        </>
    );
}




function App({ signOut, user }) {
    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <Header />
                < TextIntro />
                <Authentication />
            </div>
        </>
    );
}

export default App;
