// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const UserType = {
  "SEEKER": "SEEKER",
  "COACH": "COACH"
};

const { SeekerInformation, UserBasicInformation } = initSchema(schema);

export {
  SeekerInformation,
  UserBasicInformation,
  UserType
};